<template>
  <div>
    <div class="mainBody">
      <!-- 第一部分 -->

      <!-- 搜索区域 -->
      <div class="filter-bar-user-post">
        <!-- id -->
        <div>
          <label for="contactName">Id ：</label>
          <input
            type="text"
            id="contactName"
            v-model.trim="searchId"
          >
        </div>
        <!-- 业务类型 -->
        <!-- <div class="product-type" v-if="false">
          <label for="contactNumber">{{ $t('ProductType') }}：</label>
          <ProductTypes
            class="selector"
            v-if="productTypeDialog"
            @getType="getProductType"
          ></ProductTypes>
        </div> -->
        <div>
          <label>{{ $t('CreateTime') }}：</label>
          <el-date-picker
            v-model="createTime"
            value-format="yyyy-MM-dd"
            :placeholder="$t('ChooseDate')"
            style="float: left;"
          >
          </el-date-picker>
        </div>
        <el-button @click="searchMembershipPosts">{{ $t('Search') }}</el-button>
      </div>

      <!--二级导航栏-->
      <div class="navigatorLawyer">
        <span
          style="border-right:none"
          @click="active(3,'active1')"
          :class="{'active':active1===3}"
        >{{ $t('AllData') }}</span>
        <span
          style="border-right:none"
          @click="active(2,'active1')"
          :class="{'active':active1===2}"
        >{{ $t('CommerceCoop') }}</span>
        <span
          style="border-right:none"
          @click="active(0,'active1')"
          :class="{'active':active1===0}"
        >{{ $t('Buyers') }}</span>
        <span
          @click="active(1,'active1')"
          :class="{'active':active1===1}"
        >{{ $t('Sellers') }}</span>
        <span
          style="border-right:none"
          @click="active(1,'active2')"
          :class="{'active':active2===1}"
        >{{ $t('AllData') }}</span>
        <span
          style="border-right:none"
          @click="active(2,'active2')"
          :class="{'active':active2===2}"
        >{{ $t('Auditing') }}</span>
        <span
          style="border-right:none"
          @click="active(0,'active2')"
          :class="{'active':active2===0}"
        >{{ $t('Passed') }}</span>
        <span
          @click="active(3,'active2')"
          :class="{'active':active2===3}"
        >{{ $t('ManRefused') }}</span>
      </div>

      <!-- 表格 -->

      <el-table
        border
        :data="tableData"
        :header-cell-style="{background:'#CCCCCC',color:'black'}"
        style="width: 99%;margin: 0 auto"
      >
        <!-- :cell-style="getColor" -->
        <!--ID-->
        <el-table-column
          prop="chamberName"
          :label="$t('From')"
          width="200"
          align="center"
        >
        </el-table-column>
        <!--标题-->
        <el-table-column
          prop="postTitle"
          :formatter="formatTitle"
          :label="$t('Title')"
          align="center"
        >
        </el-table-column>
        <!--帖子类型-->
        <el-table-column
          prop="productTypes"
          :label="$t('PostType')"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ threePostType(scope.row.postType) }}</template>
        </el-table-column>
        <!--创建时间-->
        <el-table-column
          prop="createTime"
          :label="$t('CreateTime')"
          width="180"
          align="center"
        >
        </el-table-column>
        <!--审核时间-->
        <el-table-column
          prop="reviewTime"
          :label="$t('reviewTime')"
          width="180"
          align="center"
        >
        </el-table-column>
        <!--补充-->
        <el-table-column
          prop="additional"
          :label="$t('AdditionInfo')"
          align="center"
        >
        </el-table-column>
        <!--状态-->
        <el-table-column
          :label="$t('Status')"
          prop="status"
          width="130"
          align="center"
        >
          <template slot-scope="scope">
            <span :class="{
                  'autopass-text': scope.row.status === 0,
                  'manpass-text': scope.row.status === 1,
                  'sensitive-text': scope.row.status === 2,
                  'refuse-text': scope.row.status === 3 || scope.row.status === 4
                }">
              {{ numToStatusUPost(scope.row.status) }}
            </span>
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column
          min-width="90"
          class="operation-zone"
          :label="$t('Operation')"
          align="center"
        >
          <template slot-scope="scope">
            <!-- status状态：0:自动通过、1:人工通过、2:有敏感词、3:被拒绝、4:已过期 -->
            <!--详情-->
            <el-button
              type="text"
              size="small"
              @click="delatied(scope.row)"
              class="detail-button"
            >
              {{ $t('Detail') }}
            </el-button>
            <!--待审核 通过-->
            <el-button
              type="text"
              size="small"
              @click="passPost(scope.row.postId)"
              v-if="scope.row.status === 2"
            >
              {{ $t('Pass') }}
            </el-button>
            <!-- 从本商会删除该帖、从所有系统撤回该帖 -->
            <el-button
              type="text"
              size="small"
              @click="removePostId(scope.row)"
              v-if="scope.row.status === 0 || scope.row.status === 1"
              style="color: red"
            >
              {{ scope.row.chamberId === chamberId ? $t('RevokeAll') : $t('RevokeThis') }}
            </el-button>
            <!--待审核 拒绝-->
            <el-button
              type="text"
              size="small"
              @click="refusePost(scope.row);"
              v-if=" scope.row.status === 2"
              style="color: red"
            >
              {{ $t('Refuse') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="fen-pages">
        <el-pagination
          layout="prev, pager, next"
          background
          :total="postTotal"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 弹出拒绝页面 -->
    <!--拒绝帖子弹窗-->
    <RefuseDialog
      v-if="refuseDialog"
      :refuseDialogFlag="refuseDialog"
      :refuseDialogParam="refuseDialogParam"
      @closeRefuse="closeRefuseDialog"
    ></RefuseDialog>
  </div>
</template>

<script>
import api from "@/fetch/api";
import RefuseDialog from "@/views/PostMenu/RefuseDialog.vue";
import ProductTypes from "@/common/ProductTypes.vue";
import mixin from "@/assets/js/mixin";

export default {
  mixins: [mixin],
  name: "ReceivePost",
  components: { RefuseDialog, ProductTypes },
  data() {
    // 读取本地个人信息
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      refuseDialog: false,
      productTypeDialog: true,
      firmId: data.firmId,
      adminId: data.id,
      chamberId: data.chamberId, // 商会id
      right: Number(data.postPermission),
      active1: 3,
      active2: 1,
      // 搜索绑定的属性 搜索后续传值
      searchId: "",
      // 业务类型搜索
      ProductTypes: [],
      // 创建时间绑定的值
      createTime: "",
      // 当前页数
      currentPage: 1,
      // 帖子信息列表总条数 总条目数
      postTotal: 0,
      // index: 0,
      //显示的页数
      pageSize: 10,
      myRight: Number(data.postPermission),
      // 业务类型
      businessType: [],
      businessTypeIds: [],
      //表格数据
      tableData: [],
      imageUrl: "",
      // 成员帖子传值
      initReceivePost: {
        chamberId: data.chamberId,
        page: 1,
        currentAdmin: data.id,
        status: "", //1已通过【人工通过】 2未审核【有敏感词】 3被拒绝 全部：该字段不传 0自动通过
        postType: "", //0买家贴 1卖家帖 2合作伙伴贴 全部：该字段不传
        type: 0, //1.本系统删除 2.全系统删除,
        sendStatus: 0, //1已通过【人工通过】 2未审核【有敏感词】 3被拒绝 全部：该字段不传 0自动通过
        postOwnerId: "",
      },

      // 拒绝弹窗
      refuseDialogParam: {},
      param: {},
    };
  },
  mounted() {
    // this.getProductTypeIndex();
    this.initReceivePostData(1);
  },
  created() {},
  methods: {
    /**
     * 二级导航栏选择
     *  0:买家  1：卖家  2：合作伙伴  3：全部
     *   1:全部  0:通过   2：待审核   3：已拒绝  4:已过期
     */
    active(num, tab) {
      this[tab] = num;
      this.initReceivePostData(1);
    },
    /**
     * 初始化帖子成员列表
     *
     */
    initReceivePostData(currentPage) {
      this.currentPage = currentPage;
      this.initReceivePost.page = currentPage;
      this.active1 === 3
        ? delete this.initReceivePost.postType
        : (this.initReceivePost.postType = this.active1);
      this.active2 === 1
        ? delete this.initReceivePost.status
        : (this.initReceivePost.status = this.active2);
      // 传值
      const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
        .adminData.id;
      const params = {
        currentAdmin: currentAdminId,
        page: currentPage,
      };
      // id  js转换， '',0,NaN,null,undefined转换为false，常用if条件判断
      //id文本框绑定一个值，判断是否输入了值，
      //如果有，就在接口参数中加对应的字段，没有接口参数中就不写
      if (this.searchId) {
        params.postId = this.searchId;
      } else {
        params.postId = "";
        this.initReceivePost.postId = "";
      }
      // //业务类型
      if (this.productTypes) {
        params.productTypes = this.productTypes;
      }
      // //创建时间
      if (this.createTime) {
        params.createTime = this.createTime;
      }
      // //二级导航栏第一部分
      if (this.initReceivePost.postType !== "") {
        params.postType = this.initReceivePost.postType;
      }
      // //二级导航栏第二部分
      if (this.initReceivePost.status !== "") {
        params.status = this.initReceivePost.status;
      }

      /**
       * 获取自己商会成员发布的帖子
       */
      api.getReceivePosts(this.initReceivePost).then((res) => {
        this.tableData = res.data.pageList;
        this.postTotal = res.data.total;
      });
      // api.getReceivePosts(params).then((res) => {
      //   console.log("获取接收帖子成功", res);
      //   this.tableData = res.data.pageList;
      //   this.postTotal = res.data.total;
      // });
    },

    //搜索功能
    searchMembershipPosts() {
      // id
      this.searchId
        ? (this.initReceivePost.postId = this.searchId)
        : delete this.initReceivePost.id;
      // 业务类型
      // this.productTypes && this.productTypes.length
      //   ? (this.initPost.productTypes = this.productTypes)
      //   : delete this.initPost.productTypes;
      // 创建时间
      this.createTime
        ? (this.initReceivePost.createTime = this.createTime)
        : delete this.initReceivePost.createTime;
      this.currentPage = 1;
      this.initReceivePostData(1);
    },

    // 帖子状态
    numToStatusUPost(status) {
      switch (status) {
        case 0:
          return this.$t("AutoPass");
        case 1:
          return this.$t("ManPass");
        case 2:
          return this.$t("Sensitive");
        case 3:
          return this.$t("ManRefused");
        case 4:
          return this.$t("AutoRefused");
      }
    },

    //     展示选中产品类型
    getProductType(val) {
      // console.log("val ->", val[0]);
      this.productTypes = val[0];
    },
    // 从本商会删除该帖、从所有系统撤回该帖
    async removePostId(row) {
      if(this.right<3){
        this.$message.error(this.$t('NoRight'))
      }else{
        let msgText = "";
        if (row.chamberId === this.chamberId) msgText = this.$t("ConfirmDelete2");
        else msgText = this.$t("ConfirmDelete");
  
        const confirmResult = await this.$confirm(msgText, this.$t("Attention"), {
          confirmButtonText: this.$t("Confirm"),
          cancelButtonText: this.$t("Cancle"),
          type: "warning",
          dangerouslyUseHTMLString: true,
        }).catch((error) => error);
        if (confirmResult !== "confirm") {
          return this.$message.info(this.$t("Canceled"));
        }
        // 发起请求 传参
        const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
            .adminData.id;
        const chamberId = JSON.parse(localStorage.getItem("cacheChamber"))
            .adminData.chamberId;
        const params = {
          currentAdmin: currentAdminId,
          chamberId: chamberId,
        };
        params.barId = row.postId;
        if (row.chamberId === this.chamberId) params.type = 2;
        else params.type = 1;
        api.getDeleteById(params).then((res) => {
          if (res.code !== 200) {
            return this.$message.error(this.$t("SysBusy"));
          }
          // 成功给个提示信息
          this.$message.success(this.$t("Deleted"));
          // 刷新页面调用获取表格数据的方法
          // 刷新页面
          this.initReceivePostData(1);
        });
      }
    },
    // 跳转到详情页面
    delatied(row) {
      this.$router.push({
        name: "DetailedInformation",
        query: {
          id: row.postId,
          postType: row.postType, // 0 买家  1 卖家  2合作伙伴
          isUser: 0, // 0 从接收帖子来   1 从成员帖子传过来   2 从举报帖子来,
          routerName: "ReceivePost",
          postOwnerId: row.postOwnerId,
        },
      });
    },

    // 通过帖子
    async passPost(id) {
      if(this.right<2){
        this.$message.error(this.$t('NoRight'))
      }else{
        const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
            .adminData.id;
        const chamberId = JSON.parse(localStorage.getItem("cacheChamber"))
            .adminData.chamberId;
        const params = {
          currentAdmin: currentAdminId,
          chamberId: chamberId,
        };
        params.postId = id;
        params.sendStatus = 1;
        api.getUpdatePostById(params).then((res) => {
          if (res.code !== 200) {
            this.$message.error(this.$t("SysBusy"));
          }
          // 成功给个提示信息
          this.$message.success(this.$t("Passed"));
          // 刷新页面调用获取表格数据的方法 getUserList()
          // 刷新页面
          this.initReceivePostData(1);
        });
      }
    },

    //            拒绝帖子
    refusePost(tab) {
      if(this.right<2){
        this.$message.error(this.$t('NoRight'))
      }else{
        this.refuseDialog = true;
        let params = {
          currentAdmin: this.adminId,
        };
        params.sendStatus = 3;
        if (tab.postType === 2) {
          params.partnerId = tab.postId;
          api.getBackEndPartnerDetail(params).then((res) => {
            this.refuseDialogParam = res.data;
            this.refuseDialogParam.postType = 1;
          });
        } else {
          params.postId = tab.postId;
          api.getBackEndPostDetail(params).then((res) => {
            this.refuseDialogParam = res.data;
            this.refuseDialogParam.postType = 0;
          });
        }
        this.refuseDialogParam.isReceive = true;
      }
    },

    //   关闭弹窗
    closeRefuseDialog(val) {
      this.refuseDialog = val;
      this.initReceivePostData(this.currentPage);
    },

    /***
     * row为某一行的除操作外的全部数据
     * column为某一列的属性
     * rowIndex为某一行（从0开始数起）
     * columnIndex为某一列（从0开始数起）
     */
    // 改变状态字体颜色
    // getColor(scope) {
    //   if (scope.row.status == "人工通过") {
    //     return "green";
    //   } else if (scope.row.status == "自动通过") {
    //     return "orange";
    //   } else if (scope.row.status == "拒绝") {
    //     return "red";
    //   } else {
    //     return "red ";
    //   }
    // },

    // 点击切换效果 全部-卖家区
    // replace(i) {
    //   // this.initReceivePost.postType = i;
    //   // this.initReceivePostData(1);
    // },
    // // 点击切换效果 全部-已拒绝
    // select(i) {
    //   // this.initReceivePost.status = i;
    //   // this.initReceivePostData(1);
    // },
    // // 通过状态显示字体
    // getStatus(i) {
    //   // if (i === 1) {
    //   //   return "人工通过";
    //   // } else if (i === 2) {
    //   //   return "有敏感词";
    //   // } else if (i === 3) {
    //   //   return "拒绝";
    //   // } else if (i === 0) {
    //   //   return "自动通过";
    //   // }
    // },

    //分页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initReceivePostData(currentPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.mainBody {
  width: 1600px;
  margin-top: 5px;
}
.filter-bar-user-post {
  width: 99%;
  height: 50px;
  margin: 5px auto 10px auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #677d95;
  border-radius: 5px;
  color: white;
  padding-top: 5px;

  input {
    min-width: 200px;
    border: 1px solid white;
    margin: 8px 0;
    float: left;
  }

  div label {
    margin: 8px 0 8px 10px;
    display: block;
    float: left;
  }

  .el-button {
    margin: 5px 10px;
    width: 200px;
    height: 30px;
    line-height: 0.5px;
    background-color: #677d95;
    color: white;

    &:hover {
      background-color: #9fbee6 !important;
    }
  }
  .product-type {
    .selector {
      width: 200px;
    }
    ::v-deep.el-cascader-panel {
      display: flex;
      border-radius: 4px;
      font-size: 14px;
    }

    .el-cascader-menu:last-child .el-cascader-node {
      padding-right: 20px;
    }

    ::v-deep.el-cascader-menu__list {
      position: relative;
      min-height: 100%;
      margin: 0;
      padding: 6px 0;
      list-style: none;
      box-sizing: border-box;
    }

    ::v-deep.el-cascader {
      min-width: 200px;
      margin-right: 20px;
    }

    ::v-deep.el-scrollbar__wrap {
      overflow: hidden;
      margin-bottom: 0 !important;
      margin-right: 0 !important;
    }
  }
}
.navigatorLawyer {
  width: 99%;
  height: 80px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 5px;
  /*border:1px solid black;*/
  margin: 10px auto;
  //background-color: #425D7B;
  background-color: white;

  & > span {
    padding-top: 3px;
    text-align: center;
    border: 1px solid black;
    //background-color: #425D7B;
    line-height: 30px;
    cursor: pointer;
  }
}
.el-table th {
  background-color: #677d95;
  color: white;
  opacity: 1;
}
/*::v-deep .el-table td {
  text-align: center!important;
}*/

/*.detail-button{
  display: block;
  margin: 0 auto;
}*/
.sensitive-text {
  color: red;
}
.autopass-text {
  color: #d4b966;
}
.manpass-text {
  color: #85b32b;
}
.refuse-text {
  color: black;
}

.active {
  //background-color: #1C252C;
  background-color: #677d95;
  color: white;
}

.table {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.export-excel-wrapper {
  float: left;
}

// .green {
//   color: greenyellow !important;
// }
// .orange {
//   color: orange !important;
// }
// .red {
//   color: red !important;
// }
</style>